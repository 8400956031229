@import "../../styles/_chunk";

.categories-and-sortby-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding-bottom: 2.5rem;
  padding-top: 1.5rem;

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }

  .categories-container {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 65%;
      flex-direction: row;
      align-items: center;
      margin-bottom: 0;
    }

    @include media-breakpoint-up(lg) {
      margin-top: 0;
    }
  }

  .filters-container {
    display: flex;
    width: 100%;
    flex-direction: column;

    @include media-breakpoint-up(md) {
      width: unset;
      align-items: center;
      flex-direction: row;
    }
  }

  .type-filter,
  .sort-by {
    width: 100%;
    @include media-breakpoint-up(md) {
      width: inherit;
    }

    select {
      width: 100%;

      @include media-breakpoint-up(md) {
        width: 180px;
      }
    }
  }

  .type-filter {
    @include media-breakpoint-down(sm) {
      margin-bottom: 1.5rem;
    }
  }

  .facet-segment-title {
    display: block;
    font-size: 1.1rem;
    margin-bottom: 0.4rem;
  }

  .facet-segment {
    padding-right: 1.5rem;

    .checkbox-container {
      margin-right: 1.2rem;
    }
  }
}

.dynamic-checkboxes-container {
  flex-wrap: wrap;

  .checkbox-container {
    word-break: break-all;
    padding-bottom: 1rem;
    margin-right: 1.5rem;
    display: flex;
    align-items: center;

    label {
      margin-bottom: 0;
      cursor: pointer;
    }

    @include media-breakpoint-up(md) {
      width: unset;
      padding-bottom: 0;
    }
  }
}
